import React, { useCallback, useRef, useState } from 'react';
// import cn from 'classnames';
import Unmute from '../../../icons/mute.svg';
import Mute from '../../../icons/unmute.svg';

import { MuteButton, Wrapper } from './Video.styles';
import { useBreakpoint } from '../../../utils';
import { Icon } from '../../../components/icons';
import { Link } from 'gatsby';
import { useLocale } from '../../../services/i18n/useLocale';

const videos = {
  fr: {
    desktop:
      'https://player.vimeo.com/progressive_redirect/playback/1069284335/rendition/1080p/file.mp4?loc=external&signature=911626c9d7b26c82304a15d69187986afdf99a91dd099bfaf3820a4b8666ec39',
    mobile:
      'https://player.vimeo.com/progressive_redirect/playback/1069284312/rendition/720p/file.mp4?loc=external&signature=db836ff5ca4b12efb693c98ff1769304c8f9faa8331154e81a17a9668e66241b',
    link: '/fr/eshop/produit/cleaning-kit',
  },
  en: {
    desktop:
      'https://player.vimeo.com/progressive_redirect/playback/1070263129/rendition/1080p/file.mp4?loc=external&signature=b311391f21b67d4ebe79f6336dffac9eb92e3e5ef7d4ed824ac4f070635338ea',
    mobile:
      'https://player.vimeo.com/progressive_redirect/playback/1070263192/rendition/720p/file.mp4?loc=external&signature=f722dc855198c5e4608908211fb3ad08b9db95854f0d07741c1821a5f23da377',
    link: '/en/eshop/product/cleaning-kit',
  },
  it: {
    desktop:
      'https://player.vimeo.com/progressive_redirect/playback/1070263122/rendition/1080p/file.mp4?loc=external&signature=96c0bfcba3d324876eb15458f23274db74feb7c4864e8bcc6de31896177c7c8c',
    mobile:
      'https://player.vimeo.com/progressive_redirect/playback/1070263181/rendition/720p/file.mp4?loc=external&signature=66fc748c4bcca44689a6b30b31e3b4d48910c97f5ac75113c7b8491d6b98415a',
    link: '/it/eshop/product/cleaning-kit',
  },
  de: {
    desktop:
      'https://player.vimeo.com/progressive_redirect/playback/1070263091/rendition/1080p/file.mp4?loc=external&signature=5e41ba33333e9f74e32261e9f92a6de1c16feeeb74af65c30c7f3d73049802b6',
    mobile:
      'https://player.vimeo.com/progressive_redirect/playback/1070263152/rendition/720p/file.mp4?loc=external&signature=3ee7d29ce37672bcf24e3ec9fe838b38e3338c2e66430e1623920ddd1aa120c5',
    link: '/de/eshop/product/cleaning-kit',
  },
  es: {
    desktop:
      'https://player.vimeo.com/progressive_redirect/playback/1070263103/rendition/1080p/file.mp4?loc=external&signature=b61b4c0a7b6de9070ffb62606c6bbf6c6652e537da7c24f760fa759501e39707',
    mobile:
      'https://player.vimeo.com/progressive_redirect/playback/1070263166/rendition/720p/file.mp4?loc=external&signature=ccd6f8d05976844389ba386a0269aa6376c22e14d09ede259abdb2aa5e1d5d38',
    link: '/es/eshop/product/cleaning-kit',
  },
};
videos.be = videos.fr;

/*baseline, products, */
const HomepageCoverVideo = ({}) => {
  const device = useBreakpoint();
  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const intl = useLocale();

  const videoRef = useRef(null);
  const isMobile = device === 'mobile';
  const isSSR = device === 'ssr';

  const onVideoClicked = useCallback((e) => {
    const player = videoRef?.current;
    if (!player) {
      return;
    }

    if (player.paused) {
      player.play();
    }
  }, []);

  const onVideoPlay = useCallback((e) => {
    const player = videoRef?.current;

    if (!player) {
      return;
    }

    setPlaying(!player.paused);
  }, []);

  const onLoaded = useCallback((e) => {
    setLoaded(videoRef.current.readyState >= 2);
  });

  // const showPlayButton = loaded && !playing;
  console.log('muted', muted);

  const videoInfos = videos[intl.locale] || videos.en;
  return (
    <Wrapper>
      <h1>Ipone spécialiste des huiles pour moto et scooter</h1>
      <div className="container">
        <Link to={videoInfos.link} className="video" onClick={onVideoClicked}>
          {/* {playing && (
            <MuteButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMuted((value) => !value);
                return false;
              }}
              className={muted ? 'unmute' : 'mute'}
            >
              <Icon src={muted ? Unmute : Mute} />
            </MuteButton>
          )} */}
        </Link>
        <div className="fx">
          {!isSSR && !isMobile && (
            <video
              ref={videoRef}
              autoPlay
              loop
              muted={muted}
              playsInline
              width="100%"
              className="desktop"
              onPlaying={onVideoPlay}
              onLoadedData={onLoaded}
            >
              <source src={videoInfos.desktop} />
            </video>
          )}
          {!isSSR && isMobile && (
            <video
              ref={videoRef}
              autoPlay
              muted={muted}
              loop
              playsInline
              width="100%"
              className="mobile"
              onPlaying={onVideoPlay}
              onLoadedData={onLoaded}
            >
              <source src={videoInfos.mobile} />
            </video>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default HomepageCoverVideo;
